import styled from 'styled-components'
import {
  darkBlueGrey,
  errorValidation,
  warmGrey,
  greenColor,
  coolGrey
} from 'components/baseColor'
import { 
  IS_FRAME_MOBILE, 
  IS_FRAME_TABLET 
} from 'utils'

export const WrapperContent = styled.div`
  margin-top: 1em;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px ${darkBlueGrey};
  background-color: #fbfbfb;
  padding: 1.3em;
  display: grid;
  justify-items: flex-start;
  grid-auto-flow: row;
  grid-row-gap: 1em;

  ${IS_FRAME_TABLET} {
    border: none;
    padding: 0;
    margin-bottom: 1rem;

    button {
      padding: 0.5rem 0;
      font-size: 0.9rem;
      margin-top: 1rem;
    }
  }

  ${IS_FRAME_MOBILE} {
    border: none;
    padding: 0;
  }
`

export const Title = styled.p`
  margin: 0;
  font-size: 1.1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${coolGrey};
  text-transform: uppercase;

  ${IS_FRAME_MOBILE} {
    font-size: 1rem;
    margin: 10px 0;
  }
`

export const SavePayment = styled.p`
  margin: 0;
  font-size: 1.1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${greenColor};
  margin: 10px 0;

  ${IS_FRAME_TABLET} {
    text-align: center;
  }

  ${IS_FRAME_MOBILE} {
    text-align: center;
    font-size: 1rem;
  }
`

export const SpaceBetweenContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const TextBold = styled.b`
  font-size: 1.2em;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${warmGrey};
  text-transform: uppercase;
`

export const PricingText = styled.b`
  font-size: 1.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${darkBlueGrey};
`

export const WrapperInput = styled.div`
  > * {
    margin-bottom: 0;
    width: 17.5em;
  }
`

export const Hr = styled.hr`
  background-color: ${warmGrey};
  height: 1px;
  width: 100%;
  margin: 1.7em 0;
`

export const BasePaymentSection = styled.div`
  ${IS_FRAME_TABLET} {
    margin-top: 2rem;
  }

  ${IS_FRAME_MOBILE} {
    margin-top: 1rem;
  }
`

export const BaseTotalPayment = styled.p`
  margin: 0;
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${coolGrey};
`

export const ErrorText = styled.p`
  margin: 0 auto;
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${errorValidation};
`

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  width: 100%;
  align-items: center;

  ${IS_FRAME_TABLET} {
    grid-template-columns: 3fr 1fr;
  }

  ${IS_FRAME_MOBILE} {
    margin-top: -1rem;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 0.5rem;
  }
`
