import React, { useState , useEffect } from 'react'
import { navigate } from 'gatsby'
import { 
  useSelector, 
  useDispatch 
} from 'react-redux'
import { 
  InputText, 
  Button,
  DesktopOnly,
  MobileOnly 
} from 'components'
import AuthWall from 'domains/Cart/AuthWall'
import {
  WrapperContent,
  PricingText,
  TextBold,
  SpaceBetweenContainer,
  SavePayment,
  Title,
  Hr,
  BasePaymentSection,
  BaseTotalPayment,
  ErrorText,
  Grid
} from './styles'
import { SubTitle } from '../styles'
import {
  FieldPaymentPeriod,
  FieldStorage
} from '../models/commons'
import { CART_TYPES } from 'store/types'
import { formatMoney } from 'utils'
import API, { AccountURL, TransactionURL } from 'commons/API'
import { useToasts } from 'react-toast-notifications'
import { PaymentCalculation } from 'utils/paymentCalculation'
import { useIntl } from 'react-intl'


export default function PaymentSection() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const intl = useIntl()

  const { token: tokenDashboard, isLoading: isTokenLoading } = useSelector((state) => state.tokenDashboard)
  const { selectedPackage, ...cart } = useSelector((state) => state.cart)

  const [referralCode, setReferralCode] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { currency } = cart.detailCurrency
  const [showAuthWall, setShowAuthWall] = useState(false)
  const keysProduct = Object.keys(selectedPackage.products)

  // reset referral
  useEffect(() => {
    dispatch({ 
      type         : CART_TYPES.SET_REFERRAL_CODE, 
      referralCode : '' 
    })

    dispatch({ 
      type     : CART_TYPES.SET_DISCOUNT, 
      discount : 0
    })
  }, [dispatch])

  async function handlePlaceOrder() {
    setLoading(true)
    setErrorMsg('')

    if (tokenDashboard && keysProduct.length > 0) {

      // check is CRM company
      await API.get(AccountURL.GetCheckCrmCompany, {
        headers: {
          authorization: `Bearer ${tokenDashboard.token}`
        }
      })
        .then((response) => {
          const isCrmAccount = response.data
          if(isCrmAccount) navigate('/payment')
          else{
            addToast('Company is not registered on any account', { appearance: 'warning' })
          }
        })
        .catch((error) => {
          if(error.message) {
            addToast(`${error.message}. Please Check Your Internet Connection`, { appearance: 'warning' })
          }
          else {
            addToast(error.response.data.message, { appearance: 'warning' })
          }
        })
    }
    else if (!tokenDashboard & !isTokenLoading) setShowAuthWall(true)
    
    setLoading(false)
  }

  const handleValidateRefCode = async () => {
    await API.post(TransactionURL.PostValidateRefCode,
      {
        referral_code: referralCode
      },
      {
        headers: { 
          authorization: `Bearer ${tokenDashboard.token}` 
        }
      }
    )
      .then((response) => {
        // discount is on %
        const discountData = response.data.discount
        dispatch({ 
          type     : CART_TYPES.SET_DISCOUNT, 
          discount : discountData
        })

        dispatch({ 
          type         : CART_TYPES.SET_REFERRAL_CODE, 
          referralCode : referralCode
        })
      })
      .catch((error) => {
        dispatch({ 
          type     : CART_TYPES.SET_DISCOUNT, 
          discount : 0
        })

        dispatch({ 
          type         : CART_TYPES.SET_REFERRAL_CODE, 
          referralCode : ''
        })

        if(error.message === 'Network Error') {
          addToast(`${error.message}. Please Check Your Internet Connection`, { appearance: 'warning' })
        }
        else {
          addToast(error.response.data.message, { appearance: 'warning' })
        }
      })
  }
  
  const Discount = () => {
    if(cart.discount > 0) return (
      <SavePayment>
        { `- ${formatMoney(PaymentCalculation({
          subTotal : cart.subTotal,
          discount : cart.discount,
          vat      : cart.vat
        }).discountAmount, { currency }) }`
        }
      </SavePayment>
    )

    return (<TextBold>-</TextBold>)
  }

  return (
    <BasePaymentSection>
      { showAuthWall && <AuthWall handleClose={ () => setShowAuthWall(false) }/> }
      
      <MobileOnly>
        <Title>
          { intl.formatMessage({ id: '52cd63d2d' }) }
        </Title>

        <Grid>
          <InputText 
            name='referralCode'
            placeholder={ intl.formatMessage({ id: 'e3a34d9e2' }) }
            value={ referralCode }
            onChange={ (e) => setReferralCode(e.target.value) }
          />
          <Button 
            disabled={ !referralCode }
            type='warning'
            onClick={ handleValidateRefCode }
            isLoading={ isTokenLoading }
          >
            { intl.formatMessage({ id: '62ff2374f' }) }
          </Button>
        </Grid>

        <BaseTotalPayment>
          { intl.formatMessage({ id: '15a72c471' }) }
        </BaseTotalPayment>
      </MobileOnly>

      <DesktopOnly>
        <SubTitle>
          { intl.formatMessage({ id: '2fc49d84c' }) }
        </SubTitle>
      </DesktopOnly>
      <WrapperContent>
        <DesktopOnly style={{ width: '100%' }}>
          <FieldStorage />
          <FieldPaymentPeriod />

          <Title>
            { intl.formatMessage({ id: '52cd63d2d' }) }
          </Title>

          <Grid>
            <InputText 
              name='referralCode'
              placeholder={ intl.formatMessage({ id: 'e3a34d9e2' }) }
              value={ referralCode }
              onChange={ (e) => setReferralCode(e.target.value) }
            />
            <Button 
              disabled={ !referralCode }
              type='warning'
              onClick={ handleValidateRefCode }
              isLoading={ isTokenLoading }
            >
              { intl.formatMessage({ id: '62ff2374f' }) }
            </Button>
          </Grid>

          <Title>
            { intl.formatMessage({ id: '15a72c471' }) }
          </Title>
        </DesktopOnly>

        <SpaceBetweenContainer>
          <TextBold>
            { intl.formatMessage({ id: '3e88df800' }) }
          </TextBold>
          <PricingText>
            { formatMoney(cart.subTotal, { currency }) }
          </PricingText>
        </SpaceBetweenContainer>

        <SpaceBetweenContainer>
          <TextBold>
            { intl.formatMessage({ id: '7cb5d176e' }) }
          </TextBold>
          <Discount />
        </SpaceBetweenContainer>

        <SpaceBetweenContainer>
          <TextBold>vat ({ cart.vat }%)</TextBold>
          <PricingText>{ formatMoney(PaymentCalculation({
            subTotal : cart.subTotal,
            discount : cart.discount,
            vat      : cart.vat
          }).vatAmount, { currency }) }</PricingText>
        </SpaceBetweenContainer>

        <Hr />
        <SpaceBetweenContainer>
          <TextBold>
            { intl.formatMessage({ id: 'eb8239389' }) }
          </TextBold>
          <PricingText>{ formatMoney(PaymentCalculation({
            subTotal : cart.subTotal,
            discount : cart.discount,
            vat      : cart.vat
          }).grandTotalAmount, { currency }) }</PricingText>
        </SpaceBetweenContainer>

        { errorMsg && <ErrorText>{ errorMsg }</ErrorText> }

        <Button 
          isLoading={ isLoading }
          width="100%" 
          buttonType="warning" 
          onClick={ handlePlaceOrder } 
        >
          { intl.formatMessage({ id: 'fe9a01c7a' }) }
        </Button>
      </WrapperContent>
    </BasePaymentSection>
  )
}
