import React from "react";

import CheckoutView from "domains/Checkout";
import Layout from "components/Layout";
import SEO from "components/seo";

function CheckoutPage(props) {
  return (
    <>
      <SEO title="Checkout Products" />
      <Layout>
        <CheckoutView {...props} />
      </Layout>
    </>
  );
}

export default CheckoutPage;
