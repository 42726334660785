import styled, { css } from 'styled-components'
import { coolGrey, darkBlueGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  width: 90vw;
  margin: auto;
  padding-top: 4rem;
  max-width: 1600px;

  ${IS_FRAME_MOBILE} {
    padding: 1rem;
  }
`

export const SubTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${coolGrey};
  margin: 0;
  text-transform: uppercase;

  ${IS_FRAME_MOBILE} {
    font-size: 1.2rem;
  }
`

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  grid-column-gap: 6em;

  ${IS_FRAME_TABLET} {
    display: flex;
    flex-direction: column;
  }

  ${IS_FRAME_MOBILE} {
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled.h1`
  text-align: center;
  margin: 0;
  margin-bottom: 3rem;
  font-size: 3em;
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
`

export const WrapperQtyButton = styled.div`
  grid-area: quantityButton;
  justify-self: flex-end;
  margin-right: 2em;
  margin-left: -10em;

  ${IS_FRAME_MOBILE} {
    margin: 0;
    justify-self: flex-start;
    margin-top: 5px;
  }
`

export const HeaderListProduct = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  :nth-last-child(1) {
    justify-self: self-end;
  }
`

export const WrapperProducts = styled.div`
  margin-top: 1em;

  ${IS_FRAME_MOBILE} {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 10px;
  }
`

export const Product = styled.div`
  color: ${darkBlueGrey};
  border: solid 1px ${darkBlueGrey};
  background-color: #fff;
  padding: 8px;
  padding-left: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "title . iconTrash"
    "title saveMoney quantityButton"
    "title price quantityButton"
    ". desc quantityButton";

  ${({ showSavedPrice }) => !showSavedPrice &&
    css`
      grid-template-areas:
        "title . iconTrash"
        "title price quantityButton"
        "title desc quantityButton"
        ". desc quantityButton";
    `}

  .IconTrash {
    justify-self: flex-end;
    grid-area: iconTrash;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    path {
      fill: ${darkBlueGrey};
    }
  }

  ${IS_FRAME_MOBILE} {
    border-radius: 3px;
    padding: 10px;
    grid-template-areas:
      "title title iconTrash"
      "quantityButton quantityButton saveMoney"
      "quantityButton quantityButton price"
      "quantityButton quantityButton desc";
  }
`

Product.Title = styled.p`
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  grid-area: title;
  margin-top: 0.2em;
`

Product.SaveMoney = styled.del`
  font-size: 1.3em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  grid-area: saveMoney;
  align-self: self-end;
  text-align: center;
`

Product.Content = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
`

Product.Price = styled.p`
  grid-area: price;
  font-size: 2em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  text-align: center;
`

Product.Desc = styled.p`
  grid-area: desc;
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
  text-align: center;
  margin-top: -0.3em;
`

export const WrapperStorage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const WrapperField = styled.div`
  margin: 10px 0;
`
