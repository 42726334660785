import React, { useMemo, useEffect, useState } from 'react'
import { CART_TYPES } from 'store/types'
import { useDispatch, useSelector } from 'react-redux'

import Select from 'components/Select'
import QuantityButton from 'components/QuantityButton'
import { SavePayment, Title } from '../PaymentSection/styles'
import { WrapperStorage, WrapperField } from '../styles'

import API, { PricingURL } from 'commons/API'
import { useIntl } from 'react-intl'

export const HIDE_THIS_FEATURE = true

export function FieldPaymentPeriod() {
  const dispatch = useDispatch()
  const { paymentPeriod, isAnnually } = useSelector((state) => state.cart)
  const [isLoading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const intl = useIntl()

  useEffect(() => {
    (async function getData() {
      setLoading(true)

      try {
        const fetchAPI = await API(PricingURL.GetListPaymentPeriod)
        const { billing_types } = await fetchAPI.data

        setList(billing_types)
      } catch (err) {
        setLoading(false)
        if (err?.response?.status !== 404) throw new Error(err)
      }

      setLoading(false)
    })()
  }, [])

  const selectedPeriod = useMemo(() => {
    const regexFindText = new RegExp(`${paymentPeriod.name}`, 'gi')
    const getData = [...list].reduce((acc, period) => {
      if (period.name.search(regexFindText) > -1) acc = period
      return acc
    }, {})

    return getData
  }, [list, paymentPeriod.name])

  function handleChangePeriod(paymentPeriod) {
    dispatch({ type: CART_TYPES.CHANGE_PAYMENT_PERIOD, paymentPeriod })
  }

  return (
    <WrapperField>
      <Title>{ intl.formatMessage({ id: '60f151225' }) }</Title>
      <Select
        key={ selectedPeriod && (selectedPeriod.name === 'Annually' || selectedPeriod.name === 'Monthly') ? intl.formatMessage({ id: `${selectedPeriod.name}-xth` }) : selectedPeriod?.name }
        selectedItem={ selectedPeriod }
        onChange={ handleChangePeriod }
        options={ list }
        isLoading={ isLoading }
        placeholder="Select payment period"
      />
      { !isAnnually && <SavePayment>{ intl.formatMessage({ id: 'ea2f71ac4' }) }</SavePayment> }
    </WrapperField>
  )
}

export function FieldStorage() {
  const { selectedPackage, isCustom } = useSelector((state) => state.cart)

  if (HIDE_THIS_FEATURE) return null
  return (
    <WrapperStorage>
      <Title>storage</Title>
      <QuantityButton
        key={ selectedPackage?.id }
        total={ selectedPackage?.storage }
        disabled={ !isCustom }
        hideTitle
        unitName="GB"
        unitPosition="right"
      />
    </WrapperStorage>
  )
}
