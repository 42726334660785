import React, { useMemo, useEffect } from 'react'
import { navigate } from 'gatsby'
import { IconTrash } from '@qlue/icon-kit'
import { useSelector, useDispatch } from 'react-redux'
import { CART_TYPES } from 'store/types'
import {
  Wrapper,
  WrapperProducts,
  Product,
  SubTitle,
  Body,
  Title,
  WrapperQtyButton
} from './styles'
import { formatMoney } from 'utils'
import QuantityButton from 'components/QuantityButton'
import PaymentSection from './PaymentSection'
import MobileOnly from 'components/MobileOnly'
import { 
  FieldPaymentPeriod, 
  FieldStorage 
} from './models/commons'
import { useIntl } from 'react-intl'

const HIDE_THIS_FEATURE = true
const USER_SUBSCRIPTION = {
  name  : 'Mobile Field Officers', // User Subscription OR Mobile Field Officers
  id    : 'ba6f00db-12ad-4dba-a1ad-cfe022f82a1f',
  alias : 'qluework'
}

export default function CheckoutView({ location }) {
  const dispatch = useDispatch()
  const { selectedPackage, packages, ...cart } = useSelector((state) => state.cart)
  const keysProduct = Object.keys(selectedPackage.products || [])
  const intl = useIntl()

  useEffect(() => {
    if(!selectedPackage.name) navigate('/pricing')
  }, [selectedPackage.name])

  const removeProduct = (productId) => () => {
    if (cart.isCustom) dispatch({ type: CART_TYPES.REMOVE_PRODUCT, productId })
  }

  return (
    <Wrapper>
      <Title>
        { intl.formatMessage({ id: '98df43fbd' }) }
      </Title>
      <Body>
        <div style={{ width: '100%' }}>
          <SubTitle> { intl.formatMessage({ id: '8861a171a' }) } ({ selectedPackage.name })</SubTitle>
          <MobileOnly>
            <FieldPaymentPeriod />
            <FieldStorage />
          </MobileOnly>
          <WrapperProducts>
            { keysProduct.map((id) => (
              <ProductItem key={ id } { ...{ removeProduct, cart, selectedPackage, id } } />
            )) }
          </WrapperProducts>
        </div>
        <PaymentSection />
      </Body>
    </Wrapper>
  )
}

const ProductItem = ({ removeProduct, cart, selectedPackage, id }) => {
  const dispatch = useDispatch()
  const selectedProduct = selectedPackage.products[id]
  const qtyProduct = selectedProduct.product_account_package.quantity
  const { currency } = cart.detailCurrency
  const price = useMemo(() => {
    const data = {
      annually : selectedProduct.price_annually,
      monthly  : selectedProduct.price_monthly
    }

    selectedProduct.product_prices?.forEach((price) => {
      const regex = new RegExp(currency, 'gi')

      if (price.currency.name.search(regex) > -1) {
        data.monthly = price.price_monthly || 0
        data.annually = price.price_annually || 0
      }
    })

    return { total: cart.isAnnually ? data.annually : data.monthly, ...data }
  }, [
    cart.isAnnually,
    currency,
    selectedProduct.price_annually,
    selectedProduct.price_monthly,
    selectedProduct.product_prices
  ])

  const getSavedPrice = price.monthly * 12 * qtyProduct
  const showSavedPrice = cart.isAnnually && price % getSavedPrice > 0

  const handleChangeQtyProduct = (productId, alias, num) => {
    if (num === 0) removeProduct(productId)()
    // User Subscription OR Mobile Field Officers minimum quantity is 3
    if (num < 3 && USER_SUBSCRIPTION.alias === alias) removeProduct(productId)()
    else dispatch({ type: CART_TYPES.CHANGE_QTY_PRODUCT, num, productId })
  }

  return (
    <Product showSavedPrice={ showSavedPrice }>
      { !HIDE_THIS_FEATURE && (
        <IconTrash
          onClick={ removeProduct(id) }
          className={ `IconTrash ${!cart.isCustom ? 'disabled' : ''}` }
        />
      ) }
      <Product.Title>{ selectedProduct.name }</Product.Title>
      { cart.isCustom && (
        <>
          { showSavedPrice && (
            <Product.SaveMoney>{ formatMoney(getSavedPrice, { currency }) }</Product.SaveMoney>
          ) }
          <Product.Price>{ formatMoney(price.total, { currency }) }</Product.Price>
          <Product.Desc>
            /{ cart.isAnnually ? 'yr' : 'mo' } per { selectedProduct.product_unit.name }
          </Product.Desc>
        </>
      ) }
      <WrapperQtyButton>
        <QuantityButton
          total={ selectedProduct.product_account_package.quantity }
          disabled={ !cart.isCustom }
          unitName={ selectedProduct.product_unit.name }
          handleChange={ (num) => handleChangeQtyProduct(id, selectedProduct.alias, num) }
          showChangeQuantity={ false }
        />
      </WrapperQtyButton>
    </Product>
  )
}
